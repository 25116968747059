import React, {useCallback, useEffect, useState} from "react";
import {motion} from "framer-motion";
import dayjs from "dayjs";
import usePopup from "../popup/usePopup";
import {IPresent} from "./types";
import {createClasses} from "../../utils/utils";

const containerWidth = 125;
const spreadFactor = 2.6;

const presentHover = {
    scale: 1.1,
}

const presentTap = {
    click: {
        skewX: [0, 5, -5, 5, -5, 0],
        transition: {
            duration: 1
        }
    }
}

const presentTop = {
    click: {
        y: -30,
        transition: {
            delay: 1,
            duration: 0.2,
        }
    }
}

const presentWrap = {
    click: {
        height: 0,
        transition: {
            duration: 0.2,
            type: 'tween'
        }
    },
    initial: {
        transition: {
            type: 'tween'
        }
    }
}

const presentNumber = {
    click: {
        opacity: 0,
        transition: {
            duration: 1,
        }
    }
}

const presentConfettiGroup = {
    click: {
        zIndex: 1,
        transition: {
            delay: 1.3,
        }
    }
}


const presentConfetti = (delayOffset?: number) => {
    const posX = (Math.random() * containerWidth - (containerWidth / 2)) * (Math.floor(Math.random() * 2) === 1 ? -spreadFactor : spreadFactor);
    const posY = (Math.random() * -200) - 20;

    return {
        click: {
            translateX: [posX / 5, posX, posX],
            translateY: [0, posY, 75],
            opacity: [0, 0.5, 0],
            transition: {
                duration: 3,
                times: [0, 0.2, 1],
                delay: 1.2 + (delayOffset ?? 0),
            }
        }
    }
}

const color = ['#FF7676', '#FFF176', '#76A5FF'];

const Present = ({disabled = false, ...props}: IPresent) => {
    const [isClicked, setIsClicked] = useState<boolean>(false);
    const [isWrapRemoved, setIsWrapRemoved] = useState<boolean>(false);
    const {isVisible, setIsVisible, setEntry} = usePopup();

    useEffect(() => {
        if (!isVisible) {
            setIsClicked(false);
        }
    }, [isVisible]);

    const openPresent = useCallback(() => {
        if (!disabled) {
            setIsClicked(prevState => !prevState);
            setIsWrapRemoved(true);
            setIsVisible((prevState) => !prevState);
            setEntry(props);
        }
    }, [props, setEntry, setIsVisible, disabled]);

    return (
        <motion.div className={createClasses(["present", disabled ? 'disabled' : ''])}
                    onTouchStart={openPresent}
                    onMouseDown={openPresent}
                    variants={presentTap}
                    animate={isClicked ? "click" : ""}
                    whileHover={presentHover}>
            <motion.div variants={presentWrap} animate={isClicked || isWrapRemoved ? "click" : "initial"}
                        className={'present-wrap'}/>
            <motion.div variants={presentTop} animate={isClicked ? "click" : "initial"} className={"present-top"}/>
            <motion.div variants={presentNumber} animate={isClicked ? "click" : "initial"} className={'present-number'}>
                <span>{dayjs(props.publish).date()}</span></motion.div>
            {isClicked && (
                <motion.div className={"present-confetti"} variants={presentConfettiGroup}
                            animate={isClicked ? "click" : "initial"}>
                    {new Array(100).fill(undefined).map((_, index) => {
                        return (
                            <motion.div style={{background: color[Math.floor(Math.random() * 3)]}}
                                        variants={presentConfetti()} animate={isClicked ? "click" : "initial"}
                                        key={index}/>
                        )
                    })}
                    {new Array(50).fill(undefined).map((_, index) => {
                        return (
                            <motion.div style={{background: color[Math.floor(Math.random() * 3)]}}
                                        variants={presentConfetti(0.5)} animate={isClicked ? "click" : "initial"}
                                        key={index}/>
                        )
                    })}
                </motion.div>
            )}
            <motion.div className={"present-box"}/>
        </motion.div>
    )
}

export default Present;