import RestrictedRoute from "./RestrictedRoute";
import {Switch} from "react-router-dom";
import Login from "../../pages/Login";
import Home from "../../pages/Home";
import React from "react";
import Error from "../../pages/Error";
import DefaultRoute from "./DefaultRoute";
import Calendar from "../../pages/Calendar";
import {IRoutes} from "./types";

const routes: Array<IRoutes> = [
    {
        component: Home,
        path: '/',
        exact: true,
        redirectTo: '/login',
        auth: true,
    },
    {
        component: Login,
        path: '/login',
        redirectTo: '/',
    },
    {
        component: Calendar,
        path: '/calendar',
        auth: true,
        redirectTo: '/login',
    },
    {
        component: Error,
        path: '',
    },
];

const Routes = () => {
    return (
        <Switch>
            {routes.map(({auth = false, ...rest}, index) => {
                if (auth) return <RestrictedRoute {...rest} />
                return <DefaultRoute {...rest}/>
            })}
        </Switch>
    )
}

export default Routes;