class Snowflake {
    x = 0;
    y = 0;
    vx = 0;
    vy = 0;
    r = 0;
    o = 0;
    width = 0;
    height = 0;

    constructor(width: number, height: number) {
        console.log(width, height);
        this.width = width;
        this.height = height;
    }

    reset() {
        this.x = Math.random() * this.width;
        this.y = Math.random() * -this.height;

        this.vx = 1 - Math.random() * 3;
        this.vy = 0.5 + Math.random();

        this.r = 1 + Math.random() * 2;
        this.o = 0.5 + Math.random() * 0.5;
    }
}

export default Snowflake;