import React from "react";
import {IButton} from "./types";
import {motion} from "framer-motion";

const buttonAnimation = {
    whileHover: {
        scale: 1.05,
        transition: {
            duration: 0.3
        }
    }
}

const Button = ({children, onClick, type}: IButton) => {

    return (
        <motion.button onClick={onClick} className={type} {...buttonAnimation}>
            {children}
        </motion.button>
    )
}

export default Button;