import axios, {AxiosResponse, CancelTokenSource} from "axios";
import useAuth from "../providers/AuthProvider/useAuth";
import {useCallback} from "react";

const useApiRequest = () => {
    const {user} = useAuth();

    const onSuccess = useCallback((response: AxiosResponse) => {
        console.log(response);
        return response;
    }, []);

    const onError = useCallback((error: any) => {
        if (error.response) {
            const {response: {status}} = error;
            console.log(status)
        } else {
            console.error('Error Message: ', error.message);
            return Promise.reject(error.response || error.message);
        }
    }, []);

    return useCallback(async (options: any, cancelToken?: CancelTokenSource, token?: string) => {
        return axios({
            baseURL: process.env.REACT_APP_API_URL,
            ...options,
            headers: {
                Authorization: `Bearer ${token || user?.token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: cancelToken?.token,
        }).then(onSuccess).catch(onError);
    }, [user, onSuccess, onError]);
}

export default useApiRequest;