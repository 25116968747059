import React, {useCallback, useEffect, useReducer} from "react";
import Context from "./Context";
import querystring from "querystring";
import reducer from "./reducer";
import UserEnums from "./reducer/UserEnums";
import useApiRequest from "../../utils/useApiRequest";

const AuthProvider: React.FC = ({children}) => {
    const request = useApiRequest();
    const [user, dispatch]: any = useReducer<any>(reducer, {});

    useEffect(() => {
        const token = localStorage.getItem('user_token');
        const details = localStorage.getItem('user_details');
        if (token && details) {
            dispatch({
                type: UserEnums.LOGIN, user: {
                    token,
                    ...JSON.parse(details)
                }
            });
        }
    }, []);

    const login = useCallback(async (username: string, password: string) => {
        const response = await request({
            url: '/login',
            method: 'post',
            data: querystring.stringify({
                username,
                password,
            })
        });
        if (response?.status === 200) {
            const {data: {token, user}} = response;
            localStorage.setItem('user_token', token);
            localStorage.setItem('user_details', JSON.stringify(user));
            dispatch({type: UserEnums.LOGIN, user: {token, ...user}});
        }
    }, [user]);

    const isLoggedIn = useCallback(() => {
        return user?.user?.token !== undefined;
    }, [user]);

    const logout = useCallback(() => {
        if (isLoggedIn()) {
            dispatch({type: UserEnums.LOGOUT});
        }
    }, [user]);

    return (
        <Context.Provider value={{user: user.user, login, isLoggedIn, logout}}>
            {children}
        </Context.Provider>
    )
}

export default AuthProvider;