import React from "react";
import Container from "../components/utils/Container";
import {Link} from "react-router-dom";
import UserImage from "../components/user/UserImage";
import CalendarOverview from "../components/calendar/CalendarOverview";
import useAuth from "../providers/AuthProvider/useAuth";

const messages = [
    "have a great day!",
    "it's a good day to be happy!",
    "hello gorgeous!",
    "hello sunshine!",
    "enjoy your day!",
]

const Home = () => {
    const {isLoggedIn, user, logout} = useAuth();

    if (!isLoggedIn()) {
        return (
            <Container isCentered>
                <h1>Welcome!</h1>
                <Link to={"/login"}>
                    <button>Please Login</button>
                </Link>
            </Container>
        );
    }

    return (
        <Container>
            <div className={"profile flex column align-items-center"}>
                <UserImage/>
                <h2>{user?.username}</h2>
                <h1>{messages[Math.floor(Math.random() * messages.length)]}</h1>
                {isLoggedIn && (<button onClick={() => {
                    logout();
                }}>Logout</button>)}
            </div>
            <div className={"calendar"}>
                <CalendarOverview/>
            </div>
        </Container>
    );
}

export default Home;