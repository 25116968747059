import './App.css';
import Routes from "./components/routes/Routes";
import {BrowserRouter as Router} from "react-router-dom";
import Snowflakes from "./components/event/christmas/Snowflakes";
import PopupProvider from "./components/popup";
import AuthProvider from "./providers/AuthProvider/Provider";

function App() {
    return (
        <AuthProvider>
            <PopupProvider>
                <div>
                    <Snowflakes/>
                    <Router>
                        <Routes/>
                    </Router>
                </div>
            </PopupProvider>
        </AuthProvider>
    )
}

export default App;
