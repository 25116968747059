import React from "react";

interface IInput {
    name?: string,
    type: string,
    placeholder?: string,
    value: string,
    setValue?: any,
}

const Input = ({setValue, ...rest}: IInput) => (
    <input onChange={(event) => handleChange(event, setValue)} {...rest}/>
)

const handleChange = (event: any, setValue: any) => {
    setValue(event.target.value);
}

export default Input;