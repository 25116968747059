import {IReducer} from "../types";
import UserEnums from "./UserEnums";

const reducer = (prevState: IReducer, action: IReducer) => {
    switch (action.type) {
        case UserEnums.LOGIN:
            return {
                ...prevState,
                user: action.user,
            }
        case UserEnums.LOGOUT:
            localStorage.clear();
            return {};
        case UserEnums.CLEAR_ALL:
        default:
            return prevState;
    }
}

export default reducer;