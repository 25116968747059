import React from "react";
import {createClasses} from "../../utils/utils";

interface IContainer {
    children: any;
    isCentered?: boolean;
    style?: any;
}

const Container = ({children, isCentered, style}: IContainer) => (
    <div style={style} className={createClasses(["container", isCentered ? 'center' : undefined])}>
        {children}
    </div>
)

export default Container;