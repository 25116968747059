import React, {useEffect, useState} from "react";
import {getAuth, isLoggedIn} from "../../controllers/User";
import {AiOutlineUser} from "react-icons/all";

const UserImage = () => {

    const [image, setImage] = useState();

    useEffect(() => {
        if (isLoggedIn()) {
            const {user} = getAuth();
            setImage(user.profile_image);
        }
    }, []);

    return (
        <div className={"user-image flex align-items-center justify-content-center"}>
            {image && (<img alt="user" src={image}/>)
            || (
                <AiOutlineUser fontSize={60}/>
            )}
        </div>
    )
}

export default UserImage;