import React, {useEffect, useRef} from 'react';
import Snowflake from "../../../controllers/Snowflake";

const COUNT = 100;

const Snowflakes = (props: any) => {
    const canvasRef = useRef<any>(null);

    const draw = (ctx: any, snowflakes: any) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = '#ffffff';
        for (let i = 0; i < COUNT; i++) {
            const snowflake = snowflakes[i];
            snowflake.y += snowflake.vy;
            snowflake.x += snowflake.vx;

            ctx.globalAlpha = snowflake.o;
            ctx.beginPath();
            ctx.arc(snowflake.x, snowflake.y, snowflake.r, 0, Math.PI * 2, false);
            ctx.closePath();
            ctx.fill();

            if(snowflake.y > ctx.canvas.height){
                snowflake.reset();
            }
        }
    }

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas !== null) {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            canvas.style.position = 'fixed';
            canvas.style.left = canvas.style.top = '0';
            const context: any = canvas.getContext('2d');
            let animationFrameId: any;


            const snowflakes: any = [];
            for (let i = 0; i < COUNT; i++) {
                const snowflake = new Snowflake(canvas.width, canvas.height);
                snowflake.reset();
                snowflakes.push(snowflake);
            }

            //Our draw came here
            const render = () => {
                draw(context, snowflakes)
                animationFrameId = window.requestAnimationFrame(render)
            }
            render()

            return () => {
                window.cancelAnimationFrame(animationFrameId)
            }
        }
    }, [draw]);

    return (
        <canvas className={'snowflakes-canvas'} ref={canvasRef} {...props}/>
    )
}

export default Snowflakes;