import React from "react";
import {Route, Redirect} from "react-router-dom";
import useAuth from "../../providers/AuthProvider/useAuth";
import {IRoutes} from "./types";

const RestrictedRoute = ({component: Component, path, exact, redirectTo}: IRoutes) => {
    const {isLoggedIn} = useAuth();

    if (!isLoggedIn()) return <Redirect to={redirectTo || '/login'}/>;

    return <Route path={path} exact={exact} render={props =>
        <Component {...props}/>
    }/>
}

export default RestrictedRoute