import React, {useEffect, useState} from "react";
import Container from "../components/utils/Container";
import {useHistory} from "react-router-dom";
import Present from "../components/present";
import dayjs from "dayjs";
import axios from "axios";
import useApiRequest from "../utils/useApiRequest";

export interface IEntry {
    title: string,
    description: string,
    publish: string,
}

const Calendar = ({location: {state}}: any) => {
    const {push} = useHistory();
    const request = useApiRequest();
    const {id: uuid, creator} = state || {id: null};
    const [entries, setEntries] = useState<Array<IEntry>>([]);

    useEffect(() => {
        const token = axios.CancelToken.source();
        (async () => {
            const response = await request({
                url: `/calendar/${uuid}`,
                method: 'get',
            }, token);
            if (response && response.status === 200) {
                const array = response.data as Array<IEntry>;
                array.sort((a, b) => dayjs(b.publish).isBefore(a.publish) as any);
                setEntries(response.data);
            }
        })();

        return () => {
            token.cancel();
        }
    }, [uuid, request]);

    if (!uuid) {
        push('/');
    }

    return (
        <Container>
            <ul className={"calendar-days flex row wrap"}>
                {entries.map((val, index) => (
                    <Present key={index} disabled={dayjs().isBefore(dayjs(val.publish))}  {...val}/>
                ))}
            </ul>
        </Container>
    )
}

export default Calendar;