import React from "react";
import {Link} from "react-router-dom";

const ButtonBox = ({uuid, creator, children}: any) => {
    return (
        <Link style={{width: '100%'}} to={{
            pathname: "/calendar",
            state: {id: uuid, creator}
        }}>
            <button className="button-box">{children}</button>
        </Link>
    )
}

export default ButtonBox;