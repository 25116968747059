export const getAuth = () => {
    const user:any = localStorage.getItem('user');
    return {
        token: localStorage.getItem("token"),
        user: JSON.parse(user),
    }
}

export const isLoggedIn = (): boolean => {
    return !!(localStorage.getItem("token") && localStorage.getItem("user"))
}