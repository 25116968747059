import React from "react";

interface IFlexProps {
    flexDirection: 'row' | 'column',
    style?: any,
    children: any,
}

const Flex = ({style, children, ...rest}: IFlexProps) => (
    <div style={{...rest, ...style}}>
        {children}
    </div>
);

export default Flex;