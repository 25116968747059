import {UPDATE_USER, IS_LOGGED_IN} from "../../constants";

let initialState = {
    token: localStorage.getItem('user_token') || null,
    details: JSON.parse(localStorage.getItem('user_details') || '{}') || null,
    isLoggedIn: localStorage.getItem('user_token') !== null,
};

export default function user (state: any = initialState, action: any) {
    switch (action.type) {
        case UPDATE_USER:
            const {token, details} = action.payload;
            return {
                token,
                details,
                isLoggedIn: true,
            }
        case IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: state.token !== null,
            };
        default:
            return state;
    }
};