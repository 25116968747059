import React, {useState} from "react";
import Flex from "../components/utils/Flex";
import Input from "../components/forms/Input";
import Container from "../components/utils/Container";
import useAuth from "../providers/AuthProvider/useAuth";

const Login = () => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const {login, user} = useAuth();

    console.log(user)

    const process = (e: any) => {
        e.preventDefault();
        if(username !== '' && password !== ''){
            login(username, password);
        }
    }

    return (
        <Container isCentered>
            <Flex flexDirection={"column"}>
                <h1>Sign In</h1>
                <p>To continue please Login with your Credentials.</p>
                <form method={'POST'} onSubmit={process}>
                    <Flex flexDirection={"column"}>
                        <Input name={"username"} type={"text"} placeholder={"Username"} value={username}
                               setValue={setUsername}/>
                        <Input name={"password"} type={"password"} placeholder={"Password"} value={password}
                               setValue={setPassword}/>
                        <Input type={"submit"} value={"Login"}/>
                    </Flex>
                </form>
            </Flex>
        </Container>
    );
}


export default Login;