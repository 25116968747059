import React, {useEffect, useState} from "react";
import ButtonBox from "../forms/ButtonBox";
import FlexGrid from "../utils/FlexGrid";
import FlexColumn from "../utils/FlexColumn";
import useApiRequest from "../../utils/useApiRequest";

const CalendarOverview = () => {
    const request = useApiRequest();
    const [calendars, setCalendars] = useState<any>([]);

    useEffect(() => {
        (async function () {
            const result: any = await request({
                url: '/calendar',
                method: 'get',
            });
            if (result !== undefined) {
                console.log(result);
                const data = [...result.data];
                //data.push([{id: 'add'}]);
                const temp: any = [];
                const tempRow: any = [];
                let pushed: boolean = false;
                for (let i = 0; i < data.length; i++) {
                    pushed = false;
                    if ((i + 1) % 4 === 0 && i !== 0) {
                        pushed = true;
                        temp.push(tempRow);
                        tempRow.splice(0, tempRow.length);
                    }
                    tempRow.push(data[i]);
                }
                if (!pushed) {
                    temp.push(tempRow);
                }
                setCalendars(temp);
            }
        })();
    }, []);

    return calendars ? (
        <div>
            {calendars.map((calendar: any, index: number) => {
                return (
                    <FlexGrid key={`grid-${index}`}>
                        {calendar.map((c: any) => {
                            return (
                                <FlexColumn key={`column-${c.id}`}>
                                    <ButtonBox uuid={c.uuid} creator={c.is_creator}>
                                        {c.name || c.id}
                                    </ButtonBox>
                                </FlexColumn>
                            );
                        })}
                    </FlexGrid>
                );
            })}
        </div>

    ) : (<p>Couldn't load Calendars</p>);
};

export default CalendarOverview;