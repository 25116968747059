import React from "react";
import {Redirect, Route} from "react-router-dom";
import {IRoutes} from "./types";
import useAuth from "../../providers/AuthProvider/useAuth";

const DefaultRoute = ({component: Component, path, redirectTo, ...rest}: IRoutes) => {
    const {isLoggedIn} = useAuth();

    if (isLoggedIn()) return <Redirect to={redirectTo ?? '/'}/>

    return (
        <Route {...rest} render={props => <Component {...props}/>}/>
    )
}

export default DefaultRoute