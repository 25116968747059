import React, {useState} from "react";
import Context from "./Context";
import {IEntry} from "../../pages/Calendar";
import {AnimatePresence, motion} from "framer-motion";
import dayjs from "dayjs";
import Button from "../button";

const transition = {
    delay: 2,
}

const popupAnimation = {
    initial: {
        x: 0,
        opacity: 0,
        transition,
    },
    animate: {
        opacity: 1,
        transition,
    },
    exit: {
        opacity: 0,
        x: -window.innerWidth,
        transition: {
            duration: 0.3,
        }
    }
}

const headerAnimation = {
    initial: {
        rotate: 25,
        opacity: 0.15,

    },
    animate: {
        scale: [1, 1.2, 1],
        rotate: [25, 25, 25],
        opacity: [0.05, 0.2, 0.05],
        transition: {
            duration: 2,
            repeat: Infinity
        }
    }
}

const PopupProvider: React.FC<any> = ({children}) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [entry, setEntry] = useState<IEntry | undefined>(undefined);

    return (
        <Context.Provider value={{isVisible, setIsVisible, setEntry}}>
            <AnimatePresence>
                {isVisible && (
                    <motion.div className={'popup-wrapper'} {...popupAnimation}>
                        <div className={'popup'}>
                            <motion.h1 {...headerAnimation}>#{dayjs(entry?.publish).date()}</motion.h1>
                            <p>{entry?.description}</p>
                            <Button type={'secondary'} onClick={() => setIsVisible(false)}>Close</Button>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {children}
        </Context.Provider>
    )
}

export default PopupProvider;