import React, {createContext} from "react";
import {IContext} from "./types";

const Context = createContext<IContext>({
    login: () => undefined,
    logout: () => undefined,
    isLoggedIn: () => false,
    user: undefined,
});

export default Context;